import React from 'react'

export default function Information() {
  return (
    <div className='Container-2'>
      <h2>
        MC Live
      </h2>
      <div>
        <span>
          MC Live is a project started by TenCreator#2627 and TipsyTheCat#5622 <br />
          Their goal was to make a minecraft seires that span over a couple of series year <br />
          It was first made and streamed on the <date>20th of July 2022</date> and was made public <br /> 
          in season two that was streamed five days later.
        </span> <br /> <br />
        <span>
          Now they plan to open it up to anyone that is willing to be whitelisted and under go a interview<br /> 
          and trial period  
        </span> <br /> <br />
        <span>
          Currently under ownership of TenCreator#2627 and Rose 🌹#1038 due to the recent release of TipsyTheCat#5622
        </span> <br /> <br />
        <span>
          TipsyTheCat#5622 had rejoined the team!
        </span> <br /> <br />
        <span>
          <a href="https://discord.gg/8Tq2GZr24c">Join the discord here!</a>
        </span>
      </div>
    </div>
  )
}
