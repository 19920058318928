import React from 'react'

export default function Downloads() {
  return (
    <div className='Container-2'>
      <span className='downloads'>
        click <button onClick={() => {window.open('https://www.dropbox.com/s/brevllprmengl1l/MC%20Live%204.1.1%20--%20Forge.zip?dl=1', '_blank')}}>here</button> to download the mod pack <br />
        MORE COMING SOON!
      </span>
    </div>
  )
}
